import React, { useState } from 'react'
import { Box, SimpleGrid, Text, Divider, Radio, RadioGroup, List, ListIcon, ListItem } from '@chakra-ui/core'
import Title from 'elements/Typography/Title'
import Emphasis from 'elements/Typography/Emphasis'
import Layout from 'elements/Frame/Layout'
import Subtitle from 'elements/Typography/Subtitle'
import Subtle from 'elements/Typography/Subtle'
import PricingCard from 'elements/Plan/PricingCard'
import PricingTable from 'elements/Plan/PricingTable'
import plans from 'config/plans'
import { uid } from 'react-uid'

export default function PricingPage () {
  return (
    <Layout wide>

      <Title label="Plans &amp; pricing">Something for everyone. <Subtle>Seriously.</Subtle></Title>
      <PricingTable/>
    </Layout>
  )
}
